<template>
 <base-main-container>
    <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="68">
          AI is preparing your resource
        </v-progress-circular>
      </v-overlay>

       <template v-slot:centered>
      <v-card class="my-card rounded-lg" elevation="0">
        <v-card-title>InterveneXpert: Personalized Support Strategies for Educators 
        
        </v-card-title>
        <v-card-text>
         
         
          <v-text-field
            
            v-model="topic"
            class="ml-2"
            label="Specific behavior or academic area to address"
            outlined
          />
          <v-select
           
            class="mt-6 ml-2"
            append-icon="mdi-filter"
            v-model="grade"
            :items="[
              'K',
              '1',
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
              '9',
              '10',
              '11',
              '12',
            ]"
            label="Suitable for Grade level"
            outlined
            clearable
          />

          
        </v-card-text>
        <v-card-actions>
          <v-spacer />
 <v-btn class="mr-2" outlined link to="newresource">
              <v-icon class="mr-2"> mdi-backspace</v-icon> Cancel
            </v-btn>
          <v-btn
            :disabled="!((topic ))
            "
            color="#212121"
            @click="sendRequest()"
          >
            <h3
              v-if="
               !((topic))
              "
            >
              Generate please
            </h3>
            <h3 v-else style="color: white">Generate please</h3>
          </v-btn>

          <v-spacer />
        </v-card-actions>
      </v-card>
       </template>
   <v-snackbar
          v-model="showSnackbar"
          :timeout="6000"
           color="warning"
          top
          
        >
          {{ snackbarMessege }}
        </v-snackbar>
  </base-main-container>
</template>

<script>

import { getFunctions, httpsCallable } from "firebase/functions";

import { firebaseAuth } from "../../firebase";
import { db } from "../../firebase";

export default {
 
  data() {
    return {
      what: "Intervention ideas",
      topic: null,
      grade: null,
     
      isLoading: false,
     
      showSnackbar:false,
      snackbarMessege:"",
      
    };
  },
//   computed:{
//    premium(){
//       return this.$store.state.subscription ? this.$store.state.subscription.status =='active' 
//       || this.$store.state.subscription.status == 'active school'
//        || this.$store.state.subscription.status == 'trialing'
//       : false
//     }
//   },

  methods: {
  
    async sendRequest() {
      this.isLoading = true;
     
      let uid = firebaseAuth.currentUser.uid;
let newresource ={
    what: this.what,
        topic: this.topic,
        grade: this.grade,
        lastsaved: Date.now(), 
        promt: [{role: "system",content: "You are an excellent educator and teacher."},
          {role: "user", content:"I need help with intervention ideas for my class and students "},
          {role: "assistant",content:"I can help with that, what is the specific issue that you would like to intervene"},
          { role: "user", content: this.topic },
          {role: "assistant",content:"Thank you. Is this for a specific grade level?" },
          { role: "user", content: this.grade },
          {role: "assistant",content:"Thank you, I have all the details that I need to give you intervention ideas. Do you have any other details before I generate the resorce for you?"},
          {role: "user",content: "No, that is all, please give me the specific and practical interventio ideas to help my students"}
        ],
        uid:uid    
}
try {
          const userRef = db.collection("customers").doc(uid);
          const subcollectionRef = userRef.collection("saved");

          const docRef = await subcollectionRef.add(newresource)
          //console.log(docRef.id);
          newresource.id=docRef.id
        } catch (error) {
          console.error("Error adding document:", error);
        }

const functions = getFunctions();

      try { 
      const functionRef = httpsCallable(
        functions,
        "callopenai"
      );
      functionRef(newresource);
       
        this.isLoading = false;
        this.saveDialog = false;
        this.$router.push("/mydocs");

}catch (error) {
      this.isLoading = false;
      this.showSnackbar = true
      this.snackbarMessege = "The system is overloaded, please try again" 
}

      
    },
    
    
    clearSelections() {
      (this.topic = null),
        (this.grade = null)
        
    },
     
  },
  
};
</script>

<style scoped>

    .centered {
        border-radius: 15px;  
        background-color:#f3f6fc; 
        display: flex;
        justify-content: center;
        max-width: 1200px;
        height: 90vh; /* Adjust this value to match your desired height */
       
    }
    @media (min-width: 600px) {
  .my-card {
  
    width: 300px;
  }
}
@media (min-width: 960px) {
  .my-card {
   
    width: 500px;
  }
}
@media (min-width: 1264px) {
  .my-card {
   
    width: 800px;
    
  }
}
    .container.fill-height {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
}


</style>




